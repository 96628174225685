import { BaseComponent } from "../../../common/base-component.js";
import missionController from "./mission_controller.js";

import { DT_M_BREAK } from "../../../common/helpers.js";


const style = `
    :host {
        opacity: 0;
        align-self: center;
    }

    .mission-timer {
        margin: 0 0 0 4.5rem;
        text-align: center;
        color: var(--color-fg);
        line-height: 1.4;
    }

    .mission-timer__txt {
        font-size: 0.75rem;
    }

    .mission-timer__clock {
        font-size: 1.125rem;
        font-weight: 700;
        letter-spacing: 0.0675rem;
    }

    .mission-timer__labels {
        font-size: 0.625rem;
        font-style: italic;
    }

@media (max-width: ${DT_M_BREAK}) {
    .mission-timer {
        position: relative;
        margin: 0 0 0 1.5rem
    }
    .mission-timer::before {
        position: absolute;
        left: -2rem;
        top: -0.75rem;
        content: '';
        width: 1px;
        height: 4.375rem;
        background: #bababa;
    }
}
`

const markup = `
<div class="mission-timer">
    <div class="mission-timer__txt">
        Mission Ends In: 
    </div>
    <div class="mission-timer__clock" id="timer"></div>
    <div class="mission-timer__labels">Days &nbsp; Hrs &nbsp;&nbsp; Mins &nbsp;&nbsp; Secs</div>
</div>
`
// const sheet = new CSSStyleSheet();

// sheet.replace(style);

customElements.define(
        "mission-timer",
        class extends BaseComponent {
            static observedAttributes = ['data-missionEnd'];
            constructor() {
                super(style);
                //init default color if none is provided
                if(this.dataset.defaultColor == ""){
                    this.dataset.defaultColor = "var(--color-fg)";
                }
                //Sets the time to countdown to
                missionController.subscribe('data-ready', missionData => {
                    this.dataset.missionEnd = missionData.getMissionEndByAlias(this.dataset.alias);
                    this.style.opacity = 1;
                });

                const x = setInterval(() => {
                    // Get today's date and time
                    let now = new Date().getTime();
                    // Find the distance between now and the count down date
                    let distance = (this.dataset.missionEnd * 1000) - parseInt(now);
                    
                    if(distance < this.dataset.colorTime * 60 * 1000) {
                        this.shadowRoot.getElementById('timer').style.color = this.hasAttribute("data-end-color") ? this.dataset.endColor : this.dataset.defaultColor;
                        this.shadowRoot.getElementById('text').innerHTML = this.dataset.endingText;
                    } else {
                        this.shadowRoot.getElementById('timer').style.color = this.dataset.defaultColor;
                        this.shadowRoot.getElementById('text').innerHTML = this.dataset.endsText;
                    }

                    let Days, Hrs, Mins, Secs;

                    if(distance <= 0) {
                        Days = 0;
                        Hrs = 0;
                        Mins = 0;
                        Secs = 0;
                        if(this.dataset.missionEnd){
                            clearInterval(x)
                            this.shadowRoot.host.parentElement.style = 'transition: max-height .5s; max-height:0;'
                            setTimeout(()=>{
                                this.shadowRoot.host.parentElement.remove();
                            }, 500)
                        }
                    } else {
                        // Time calculations for days, hours, minutes and seconds
                        Days = Math.floor(distance / (1000 * 60 * 60 * 24));
                        Hrs = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        Mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        Secs = Math.floor((distance % (1000 * 60)) / 1000);
                    }

                    // Display the result in the element with id="datetime[i]"
                    this.shadowRoot.getElementById('timer').innerHTML = 
                    Days + ":"
                    + Hrs.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ":"
                    + Mins.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ":" 
                    + Secs.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

                }, 1000);
            }

            render() {
                return `
                    <div class="mission-timer">
                        <div class="mission-timer__txt" id="text">
                            Mission Ends In: 
                        </div>
                        <div class="mission-timer__clock" id="timer"></div>
                        <div class="mission-timer__labels">Days &nbsp; Hrs &nbsp;&nbsp; Mins &nbsp;&nbsp; Secs</div>
                    </div>
                `
            }
        },
    );